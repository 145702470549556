<template>
  <t-input
    v-model="computedValue"
    v-bind="$attrs"
    :native-type="passwordInputType"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
  >
    <template #right>
      <t-button small square soft @click="togglePasswordVisibility">
        <t-icon :icon="['fas', passwordIcon]" />
      </t-button>
    </template>
  </t-input>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  data() {
    return {
      newValue: this.value,
      showPassword: false,
    };
  },

  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },

      set(value) {
        this.newValue = value;
        this.$emit('input', value);
      },
    },

    passwordIcon() {
      return this.showPassword ? 'eye-slash' : 'eye';
    },

    passwordInputType() {
      return this.showPassword ? 'text' : 'password';
    },
  },

  watch: {
    value(value) {
      this.newValue = value;
    },
  },

  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
}; </script>
